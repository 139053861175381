<template>
<div class="cdKey">
  <div class="user-tit">我的激活码</div>
<!--  <div class="cdKey-active">-->
<!--    <p class="cdKey-active-name">激活码：</p>-->
<!--    <el-input class="cdKey-active-input" v-model="code"  placeholder="请输入激活兑换码" />-->
<!--    <p class="cdKey-active-btn" @click="activeCode">立即激活</p>-->
<!--  </div>-->
  <div v-if="tableData.length && !loading">
    <table class="table">
      <tr>
        <th>激活码</th>
        <th>认证</th>
        <th>认证剩余时间</th>
        <th>激活时间</th>
        <th>操作</th>
      </tr>
      <tr v-for="item in tableData" :key="item.id">
        <td>{{item.code}}</td>
        <td>{{item.category && item.category.name}}</td>
        <td>{{Math.ceil(item.remain_time / 60 / 60 / 24)}}天</td>
        <td>{{item.active_time}}</td>
        <td>
          <div class="table-btn" @click="goInfo(item.category.id)">认证详情</div>
        </td>
      </tr>
    </table>
  </div>
  <div class="page" v-if="tableData.length && !loading">
    <el-pagination
        @size-change="changePageSize"
        @current-change="changePage"
        :current-page="page"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>

  </div>

  <div class="no-data" v-if="!loading && !tableData.length">
    <img src="../../assets/images/common/no-data.png" width="223" height="129" />
    <p>还没有激活认证哦~</p>
  </div>
  <div style="height: 300px;position: relative" v-loading="loading" v-if="loading"></div>

  <el-dialog
      :visible.sync="activeDialog"
      width="340px"
      :show-close="false"
      @close="closeDialog"
  >
    <div slot="title"></div>

    <div class="confirm-txt">兑换成功！</div>
    <div class="confirm-txt-sub">已兑换《{{ categoryInfo.name }}》认证,可在个人中心-我的学习中查看学习</div>
    <div slot="footer" class="confirm-submit">
      <template>
        <div class="confirm-submit-btn" @click="goInfo(categoryInfo.id)">去看看</div>
      </template>
    </div>
  </el-dialog>
</div>
</template>

<script>
import util from '@/utils/index.js'
export default {
  name: "cdKey.vue",
  data(){
    return {
      tableData:[],
      activeDialog:false,
      page:1,
      pageSize:10,
      total:0,
      code:'',
      categoryInfo:{},
      loading:true,
    }
  },
  created(){
    this.getList();
  },
  methods:{
    goInfo(id){
      this.$router.push({
        path:'/attestation/detail',
        query:{
          id:id
        }
      })
      this.activeDialog = false;
      this.code = '';
      this.getList();
    },
    closeDialog(){
      this.code = '';
      this.getList();
    },
    getList(){
      let params  = {
        page:this.page,
        pageSize:this.pageSize
      }
      this.loading = true;
      this.api.user.categoryByActivedUser(params).then((res)=>{
        this.tableData = res.list;
        this.total = +res.count;
        this.loading = false;
        this.tableData.forEach((item)=>{
          item.active_time = util.timeFormatter(new Date(+item.active_time*1000), 'yyyy-MM-dd hh:mm')
        })
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changePageSize(pageSize){
      this.page = 1;
      this.pageSize = pageSize;
      this.getList();
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    activeCode(){
      if(!this.code){
        this.$message({
          message: '请输入激活码',
          type: 'warning'
        });
        return;
      }
      let params = {
        code:this.code,
        is_sure:1,
      };
      this.api.user.cdkeyActive(params).then((res)=>{
        this.activeDialog = true;
        this.categoryInfo = res.info;
      })
    }
  }
}
</script>

<style scoped lang="scss">
.cdKey{
  .user-tit{
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: bold;
  }
  .cdKey-active{
    height: 100px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px 0px rgba(0,35,136,0.08);
    border-radius: 10px;
    @include flex(row,flex-start,center);
    .cdKey-active-name{
      margin-left: 30px;
      font-size: 16px;
    }
    .cdKey-active-input{
      width: 340px;
      //border-radius: 25px;
      //border: 1px solid #DCDCDC;
    }
    .cdKey-active-btn{
      margin-left: 20px;
      @include btn(140px,50px,25px,16px,#333333);
      font-weight: bold;
    }
  }
  .table{
    margin-top: 30px;
    width: 100%;
    color: #666666;
    >tr{
      height: 70px;
      border-bottom: 1px solid $theme-border-gray;
      >th,td{
        text-align: center;
      }
    }
    >tr:first-child{
      height: 56px;
      background: #FAFBFC;
      >th{
        font-weight: bold;
        color: #333333;
      }
    }
  }
  .table-btn{
    margin: 0 auto;
    @include btn(80px,30px,15px,14px,#1664FF)
  }
  .page{
    margin: 20px;
    text-align: right;
  }
  .confirm-txt{
    margin-top: -20px;
    margin-bottom: 10px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
  .confirm-txt-sub{
    margin: 0 36px;
    line-height: 22px;
  }
  .confirm-submit{
    margin-top: -20px;
    .confirm-submit-btn{
      margin: 0 auto;
      @include btn(110px,38px,19px,14px,linear-gradient(222deg, #3A6CFF, #004FEB));
    }
  }
}
</style>
<style>
.cdKey .table-first{
  background: #FAFBFC;
  font-weight: bold;
  color: #333333;
}
.cdKey-active-input .el-input__inner{
  /*border: none;*/
  height: 50px;
  border-radius: 25px;
}
</style>
